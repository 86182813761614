/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderStatus } from './orderStatus';
import { AssistanceStatus } from './assistanceStatus';
import { DetailedOrderItemResponse } from './detailedOrderItemResponse';


export interface DetailedOrderResponse { 
    id?: number;
    items?: Array<DetailedOrderItemResponse>;
    /**
     * Tip amount
     */
    tip?: number;
    /**
     * Additional Comment
     */
    comment?: string;
    /**
     * Table Id
     */
    tableId?: string;
    /**
     * Table name
     */
    tableName?: string;
    tableAssistanceStatus?: AssistanceStatus;
    status?: OrderStatus;
    createdAt?: string;
}

