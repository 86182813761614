/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of an order
 */
export type OrderStatus = 'PENDING' | 'ACCEPTED' | 'IN_PROGRESS' | 'READY_TO_DELIVER' | 'DELIVERED' | 'PAYED' | 'CANCELED' | 'SETTLED';

export const OrderStatus = {
    Pending: 'PENDING' as OrderStatus,
    Accepted: 'ACCEPTED' as OrderStatus,
    InProgress: 'IN_PROGRESS' as OrderStatus,
    ReadyToDeliver: 'READY_TO_DELIVER' as OrderStatus,
    Delivered: 'DELIVERED' as OrderStatus,
    Payed: 'PAYED' as OrderStatus,
    Canceled: 'CANCELED' as OrderStatus,
    Settled: 'SETTLED' as OrderStatus
};

