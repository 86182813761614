import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MenuService, VersionedMenuResponse } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviewVersionedMenuResolverService implements Resolve<VersionedMenuResponse> {

  constructor(private apiConfigService: ApiConfigService, private menuApi: MenuService) {
    this.menuApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let businessId = Number(route.paramMap.get('businessId'))
    let versionId = route.paramMap.get('versionId')
    return this.menuApi.getVersionForMenu(businessId, versionId)
  }
}