import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UserIdService {

  private readonly USER_ID_KEY = environment.STORAGE_KEYS.USER_FRONTEND_ID;

  constructor() {
    this.initializeUserId();
  }

  private initializeUserId(): void {
    if (!localStorage.getItem(this.USER_ID_KEY)) {
      const userId = uuidv4();
      localStorage.setItem(this.USER_ID_KEY, userId);
    }
  }

  getUserId(): string {
    let userId = localStorage.getItem(this.USER_ID_KEY);
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem(this.USER_ID_KEY, userId);
    }
    return userId;
  }
}
