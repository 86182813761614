export const environment = {
  production: false,
  API_BASE: "https://develop.api.servito.me",
  API_BASE_PAYEMENT: "https://develop.payment.servito.me",
  URL: "https://develop.app.servito.me/b", //this fix is for boskos confort
  app_name: "Servito",
  ROLES: {
    BUSINESS_OWNER: "business-owner",
    BUSINESS_OPERATOR: "business-operator"
  },
  STORAGE_KEYS: {
    TOKEN: "TOKEN",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    USER: "USER",
    LANGUAGE: "i18n",
    CART: "cart",
    TABLE: "table",
    USER_FRONTEND_ID: "USER_FRONTEND_ID",
    RECENT_ORDERS: "recent-orders"
  },
  ORDER_RELOAD_DELAY: 5000,
  FEATURE_SWITCHES: {
    PAYMENT: false
  },
  auth0: {
    domain: 'servito-staging.eu.auth0.com',
    clientId: 'CqBnzMXGVod1hDtnoql0DEc2C5rkUqEK',
    redirectUri: window.location.origin,
    audience: 'api',
    scope: 'openid profile email'
  },
  firebase: {
    apiKey: "AIzaSyA38ds9wTga_F3QRr5Qi5GvrIr0wYtSzis",
    authDomain: "servito-testing.firebaseapp.com",
    projectId: "servito-testing",
    storageBucket: "servito-testing.appspot.com",
    messagingSenderId: "731629205514",
    appId: "1:731629205514:web:7e4f93b59ecedc5dc247f3",
    measurementId: "G-E0LTRDTJKX"
  }
};
