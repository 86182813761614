import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Configuration as BusinessApiConfiguration } from 'src/generated-sources/business-openapi';
import { Configuration as AuthApiConfiguration } from 'src/generated-sources/auth-openapi';
import { Configuration as ImageApiConfiguration } from 'src/generated-sources/image-openapi';
import { Configuration as ExportApiConfiguration } from 'src/generated-sources/export-openapi';
import { Configuration as PaymentApiConfiguration } from 'src/generated-sources/payment-openapi'
import { Configuration as ServitoAiApiConfiguration } from 'src/generated-sources/ai-openapi';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {
  apiBase: string;
  apiBasePayment: string;

  constructor() {
    this.apiBase = environment.API_BASE;
    this.apiBasePayment = environment.API_BASE_PAYEMENT;
  }


  getBusinessApiConfig(): BusinessApiConfiguration {
    return new BusinessApiConfiguration({
      basePath: this.apiBase,
      withCredentials: false,
      accessToken: localStorage.getItem(environment.STORAGE_KEYS.TOKEN)
    });
  }

  getAuthApiConfiguration(): AuthApiConfiguration {
    return new AuthApiConfiguration({
      basePath: this.apiBase,
      withCredentials: false,
      accessToken: localStorage.getItem(environment.STORAGE_KEYS.TOKEN)
    });
  }

  getImageApiConfiguration(): ImageApiConfiguration {
    return new ImageApiConfiguration({
      basePath: this.apiBase,
      withCredentials: false,
      accessToken: localStorage.getItem(environment.STORAGE_KEYS.TOKEN)
    });
  }

  getExportApiConfiguration(): ExportApiConfiguration {
    return new ExportApiConfiguration({
      basePath: this.apiBase,
      withCredentials: false,
      accessToken: localStorage.getItem(environment.STORAGE_KEYS.TOKEN)
    });
  }

  getPaymentApiConfig(): PaymentApiConfiguration {
    return new PaymentApiConfiguration({
      basePath: this.apiBasePayment,
      withCredentials: false,
      accessToken: localStorage.getItem(environment.STORAGE_KEYS.TOKEN)
    });
  }

  getServitoAIConfig(): ServitoAiApiConfiguration {
    return new ServitoAiApiConfiguration({
      basePath: this.apiBasePayment,
      withCredentials: false,
      accessToken: localStorage.getItem(environment.STORAGE_KEYS.TOKEN)
    });
  }

}
