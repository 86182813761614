/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { OptionSectionRequest } from './optionSectionRequest';
import { PriceResponse } from './priceResponse';
import { BusinessImageResponse } from './businessImageResponse';


export interface MenuItemUpdateRequest { 
    id?: number;
    /**
     * Item name
     */
    name?: string;
    /**
     * Menu Item Description
     */
    description?: string;
    image?: BusinessImageResponse;
    prices?: Array<PriceResponse>;
    optionSections?: Array<OptionSectionRequest>;
    sort?: number;
    status?: Status;
}

