import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(price: number): string {
    let formatted = price.toFixed(2);
    return formatted
    //return formatted.endsWith('.00') ? formatted.replace('.00', '.–') : formatted;
  }

}
