import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HolidayResponse, HolidaysService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class HolidaysResolverService implements Resolve<Array<HolidayResponse>> {

  constructor(private apiConfigService: ApiConfigService, private holidaysService: HolidaysService) {
    this.holidaysService.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let businessId = Number(route.paramMap.get('businessId'))
    return this.holidaysService.getHolidays(businessId)
  }
}
