import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AlertController } from '@ionic/angular';
import { BusinessResponseWithMenu, DetailedOrderItemResponse, DetailedOrderResponse, DetailedSelectedOption, OrderService, OrderStatus, OrderUpdateRequest } from 'src/generated-sources/business-openapi';

@Component({
  selector: 'app-bill-entry',
  templateUrl: './bill-entry.component.html',
  styleUrls: ['./bill-entry.component.scss'],
})
export class BillEntryComponent implements OnInit {

  @Input() order: DetailedOrderResponse
  @Input() isReorder: boolean = false
  @Input() business: BusinessResponseWithMenu
  @Input() tableId: string

  @Output() orderReload = new EventEmitter<void>();
  constructor(
    private alertController: AlertController,
    private analytics: AngularFireAnalytics,
    private orderApi: OrderService
  ) { }

  ngOnInit() { }



  getPriceInclusiveOptions(item: DetailedOrderItemResponse): number {
    let total = item.price.amount
    if (item.selectedOptions && item.selectedOptions.length > 0) {
      item.selectedOptions.forEach((option: DetailedSelectedOption) => {
        total += option.price
      });
    }
    return total;
  }
  async cancelOrder(order: DetailedOrderResponse) {
    const alert = await this.alertController.create({
      header: 'Bestellung stornieren',
      message: 'Bitte geben Sie einen Grund für die Stornierung ein.',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Grund für Stornierung',
          attributes: {
            maxlength: 255
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancellation dismissed');
          }
        },
        {
          text: 'Confirm',
          handler: (data) => {
            const reason = data.reason?.trim();
            if (reason && reason.length >= 15) {

              // Proceed with canceling the order
              this.confirmCancelOrder(order, data.reason);
            } else {
              // You can display an error if no reason is provided
              this.showErrorMessage('Der Grund für die Stornierung muss mindestens 15 Zeichen lang sein.');
              return false; // Prevent closing the alert
            }
          }
        }
      ]
    });

    await alert.present();
  }



  confirmCancelOrder(order: DetailedOrderResponse, reason: string) {
    var orderRequest = {
      id: order.id,
      status: OrderStatus.Canceled,
      reason: reason // Attach the reason to the request
    } as OrderUpdateRequest;

    var orderList = [];
    orderList.push(orderRequest);
    console.log(orderList);
    this.orderApi.updateOrder(this.business.id, orderList).subscribe(data => {
      this.orderReload.emit();
      this.analytics.logEvent('cancle_order', {
        business_id: this.business.id,
        table_id: this.tableId,
        business_url: this.business.url,
        order_id: order.id,
        reason: reason
      })
      console.log('Order canceled with reason:', reason); // Log reason for debugging
    }, error => {
      console.error('Error canceling order:', error);
    })





  }
  showErrorMessage(message: string) {
    // Optionally, show an error message to the user if they didn't provide a reason
    this.alertController.create({
      header: 'Error',
      message: message,
      buttons: ['OK']
    }).then(alert => alert.present());
  }

}
