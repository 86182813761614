/**
 * Payment API
 * API for processing payments via Stripe
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyAddress } from './companyAddress';
import { BusinessProfile } from './businessProfile';
import { Representative } from './representative';


export interface AccountRequest { 
    /**
     * Name of the company
     */
    companyName?: string;
    /**
     * Email of the company
     */
    companyEmail?: string;
    companyAddress?: CompanyAddress;
    /**
     * Phone number of the company
     */
    companyPhone?: string;
    /**
     * Tax ID of the company
     */
    taxId?: string;
    /**
     * Registration number of the company
     */
    registrationNumber?: string;
    businessProfile?: BusinessProfile;
    representative?: Representative;
    /**
     * IBAN of the account
     */
    iban?: string;
    /**
     * Name of the account holder
     */
    accountHolderName?: string;
}

