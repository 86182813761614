import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessResponse, BusinessService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessByIdResolverService implements Resolve<BusinessResponse> {

  constructor(private apiConfigService: ApiConfigService, private businessApi: BusinessService) {
    this.businessApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let id = Number(route.paramMap.get('businessId'));
    return this.businessApi.getBusinessById(id)
  }
}
