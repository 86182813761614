/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Assistance status for a table
 */
export type AssistanceStatus = 'NONE' | 'BILL' | 'WAITER';

export const AssistanceStatus = {
    None: 'NONE' as AssistanceStatus,
    Bill: 'BILL' as AssistanceStatus,
    Waiter: 'WAITER' as AssistanceStatus
};

