import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiConfigService } from 'src/app/services/config/api-config.service';
import { MenuItemService, SchemaOrgMenuItem, SchemaOrgMenuSection } from 'src/generated-sources/business-openapi';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ItemsComponent implements OnInit {
  @Input() section: SchemaOrgMenuSection
  @Input() businessId: number;
  @Input() menuId: number;
  @Input() loadingSections: number[]
  @Output() editMenuItem: EventEmitter<SchemaOrgMenuItem> = new EventEmitter<SchemaOrgMenuItem>();
  selectedItem: any;
  itemInput: any;
  isLoaded = false
  constructor(
    private apiConfigService: ApiConfigService,
    private menuItemService: MenuItemService
  ) { }

  ngOnInit() { }

  isThisSectionLoading() {
    return this.loadingSections.includes(this.section.id)
  }

  onReorderItems(event: any) {
    console.log("Reorderitems");
    event.stopImmediatePropagation();
    const item = this.section.hasMenuItem[event.detail.from]
    this.section.hasMenuItem.splice(event.detail.from, 1);
    this.section.hasMenuItem.splice(event.detail.to, 0, item);
    //section.hasMenuItem.splice(event.detail.to, 0, section);

    // Complete the reorder process and notify
    event.detail.complete();

    // Optionally send the new order to the server or update your backend
    console.log('Updated item order:', this.section.hasMenuItem);

    this.saveOrderOfMenuItemElements(this.section);
  }
  saveOrderOfMenuItemElements(section: SchemaOrgMenuSection) {

    console.log('Section:', section);
    let i = 0;
    let request: Array<SchemaOrgMenuItem> = []
    for (let item of section.hasMenuItem) {
      //item.sort = i
      let menuItemUpdate = {
        id: item.id,
        name: item.name,
        description: item.description,
        offers: item.offers,
        status: 'PUBLISHED',
        sort: i
      } as SchemaOrgMenuItem
      i++
      request.push(menuItemUpdate)
      console.log('Updated item order:', request);
    }
    this.menuItemService.updateMenuItem(this.businessId, this.menuId, section.id, request, true).subscribe((menuItemsResponseList) => {
      console.log('Menu items reordered:', menuItemsResponseList);
      //this.toastService.showSuccess(this.translateService.instant("EDIT_MENU.IS_REORDERED", { menuName: "Menu" }))
    }, error => {
      console.error('Error while saving order of menu elements:', error);
      //this.errorAlertService.presentUnexpectedErrorAlert(error)
    });
  }

  editItem(event: any, item: any) {
    event.stopPropagation();  // Prevent accordion toggle or other parent events from triggering
    event.stopImmediatePropagation();  // Prevent accordion toggle or other parent events from triggering
    console.log('Editing item:', item);
    this.selectedItem = item;  // Set the item as the one being edited
    this.itemInput = item.name;  // Pre-populate the input field with the current name
    this.editMenuItem.emit(item);  // Emit the item to the parent component
  }

}
