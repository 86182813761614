import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ItemsComponent } from './items.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [ItemsComponent],
    imports: [CommonModule, IonicModule, FormsModule],
    exports: [ItemsComponent] // Export to make it available to other modules
})
export class ItemsModule { }
