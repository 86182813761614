import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { LoginService } from '../services/auth/login.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainRoutingGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService, private loginService: LoginService) {

  }
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthenticationAndRole()
  }

  private checkAuthenticationAndRole(): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          // User is not authenticated, redirect to login
          return of(this.router.createUrlTree(['/login']));
        }
        // User is authenticated, check the role
        return this.auth.idTokenClaims$.pipe(
          map(claims => {
            const decodedToken = this.loginService.decodeJwtPayload(claims.__raw);
            const roles = decodedToken.realm_access.roles;
            if (roles?.includes(environment.ROLES.BUSINESS_OWNER)) {
              this.router.navigate(['/businesses']);
              return true;
            } else if (roles?.includes(environment.ROLES.BUSINESS_OPERATOR)) {
              this.router.navigate(['/operator']);
              return true;
            }
            else {
              // User does not have the required role, redirect to unauthorized
              return this.router.createUrlTree(['/unauthorized']);
            }
          }),
          catchError(error => {
            console.error('Error in retrieving user roles', error);
            return of(this.router.createUrlTree(['/error'])); // Redirect to an error page or handle accordingly
          })
        );
      })
    );
  }

}
