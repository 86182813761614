/**
 * Payment API
 * API for processing payments via Stripe
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChargeResponse { 
    id?: number;
    /**
     * Charge ID of the Payment Provider
     */
    paymentProviderChargeId?: string;
    /**
     * Amount charged in cents
     */
    amount?: number;
    /**
     * Currency of the charge
     */
    currency?: string;
    /**
     * Status of the charge
     */
    status?: string;
    paymentProvider?: ChargeResponse.PaymentProviderEnum;
}
export namespace ChargeResponse {
    export type PaymentProviderEnum = 'STRIPE';
    export const PaymentProviderEnum = {
        Stripe: 'STRIPE' as PaymentProviderEnum
    };
}


