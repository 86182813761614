import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppPage } from 'src/app/class/app-pages/app-page';
import { UserData } from 'src/app/class/responses/user';
import { CartItem } from 'src/app/public/ordering/classes/cart';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private userDataUpdate = new Subject<UserData>();
  private appPageUpdate = new Subject<AppPage[]>();
  private cartUpdate = new Subject<CartItem[]>();

  updateUserData(data: UserData) {
    this.userDataUpdate.next(data);
  }

  getUserDataSubject(): Subject<UserData> {
    return this.userDataUpdate;
  }

  updateAppPage(data: AppPage[]) {
    this.appPageUpdate.next(data);
  }

  getAppPageSubject(): Subject<AppPage[]> {
    return this.appPageUpdate;
  }
  updateCart(data: CartItem[]) {
    this.cartUpdate.next(data);
  }

  getCartUpdateSubject(): Subject<CartItem[]> {
    return this.cartUpdate;
  }
}
