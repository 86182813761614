/**
 * Payment API
 * API for processing payments via Stripe
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CompanyAddress { 
    /**
     * Street address
     */
    line1?: string;
    /**
     * City
     */
    city?: string;
    /**
     * State
     */
    state?: string;
    /**
     * Postal code
     */
    postalCode?: string;
    /**
     * Country
     */
    country?: string;
}

