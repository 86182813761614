// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
#splash-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #34C5D9;
  /* Hintergrundfarbe des Splash Screens */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#splash-screen img {
  width: 200px;
  /* Größe des Logos */
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/splash-screen/splash-screen.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,wCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAEJ;;AACA;EACI,YAAA;EACA,oBAAA;EACA,YAAA;AAEJ","sourcesContent":["#splash-screen {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background: #34C5D9;\n    /* Hintergrundfarbe des Splash Screens */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999;\n}\n\n#splash-screen img {\n    width: 200px;\n    /* Größe des Logos */\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
