import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ApiConfigService } from 'src/app/services/config/api-config.service';
import { MenuItemRequest, MenuItemResponse, MenuItemService, MenuItemUpdateRequest, MenuSectionService, OptionSectionResponse, PriceResponse, SchemaOrgMenuItem, SchemaOrgMenuSection, Status } from 'src/generated-sources/business-openapi';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss'],
})
export class EditItemComponent implements OnInit {
  //@Input() selectedCategory: SchemaOrgMenuSection;
  //@Input() itemForm: any; // Replace with your actual form structure
  //@Input() showOptionSections: boolean = false;

  @Input() businessId: number;
  @Input() menuId: number;

  @Output() saveItem = new EventEmitter<void>(); // Emit when saving
  @Output() cancelEdit = new EventEmitter<void>(); // Emit when canceling edit

  @Input() editingItem: MenuItemResponse

  reponses: string[] = [];
  showOptionSections: boolean = false;

  itemForm = {
    name: '',
    description: '',
    prices: [{ name: '', amount: null }],
    optionSections: [{
      name: '',
      free: false,
      options: [{ name: '', amount: null }]
    }]
  } as MenuItemRequest;

  selectedCategory: number;
  constructor(
    private apiConfigService: ApiConfigService,
    private menuSectionService: MenuSectionService,
    private menuItemService: MenuItemService
  ) {
    this.menuSectionService.configuration = this.apiConfigService.getBusinessApiConfig();
    this.menuItemService.configuration = this.apiConfigService.getBusinessApiConfig();
  }

  ngOnInit() {
    console.log('Editing item 666:', this.editingItem);
    if (this.editingItem) {
      this.initalizeData();
    } else {
      this.demoData();
    }

  }

  private initalizeData() {
    this.selectedCategory = 251;
    this.itemForm.name = this.editingItem.name;
    this.itemForm.description = this.editingItem.description;
    if (this.editingItem.optionSections) {
      this.showOptionSections = true;
    }

    //this.itemForm.prices = this.editingItem.offers;
    //this.itemForm.optionSections = this.editingItem.menuAddOn;
    //convert offerst to prices
    console.log('Editing item:', this.editingItem);
    console.log('Offers:', this.editingItem.prices);
    this.editingItem.prices.forEach((offer) => {
      this.itemForm.prices.push({ name: offer.name, amount: offer.amount, status: Status.Published });
    });
    //convert menuAddOn to optionSections
    this.editingItem.optionSections.forEach((menuAddOn) => {
      let optionSection = {
        name: menuAddOn.name,
        free: false,
        options: []
      } as OptionSectionResponse;
      menuAddOn.options.forEach((option) => {
        console.log('Option:', option);
        optionSection.options.push({ name: option.name, amount: option.amount, status: Status.Published });
      });
      this.itemForm.optionSections.push(optionSection);
    }
    );
  }


  private demoData() {
    this.selectedCategory = 251

    this.itemForm.name = 'Pizza Margherita';
    this.itemForm.description = 'Tomatensauce, Mozzarella, Basilikum';
    this.itemForm.prices = [
      { name: 'Klein', amount: 5.5, status: Status.Published },
      { name: 'Groß', amount: 8.5, status: Status.Published }
    ];
    this.itemForm.optionSections = [
      {
        name: 'Gratis Zutaten',
        free: true,
        options: [
          { name: 'Klein', amount: 0, status: Status.Published },
          { name: 'Groß', amount: 0, status: Status.Published }
        ]
      },
      {
        name: 'Extra Zutaten',
        free: false,
        options: [
          { name: 'Oliven', amount: 1, status: Status.Published },
          { name: 'Champignons', amount: 1, status: Status.Published },
          { name: 'Peperoni', amount: 1, status: Status.Published }
        ]
      }
    ];
  }



  cancelEditing() {
    this.cancelEdit.emit(); // Emit cancel event
  }


  checkData(): boolean {
    //check if the itemForm is valid
    this.reponses = [];
    if (this.itemForm.name == '' || this.itemForm.description == '') {
      if (this.itemForm.name == '') {
        this.reponses.push('Name is not valid');
      }
      if (this.itemForm.description == '') {
        this.reponses.push('Description is not valid');
      }
      return false;
    }
    for (let price of this.itemForm.prices) {
      if (price.name == '' || price.amount == null) {
        if (price.name == '') {
          this.reponses.push('Price name is not valid');
        }
        if (price.amount == null) {
          this.reponses.push('Price amount is not valid');
        }
        return false;
      }
    }
    if (!this.showOptionSections) {
      //we don't need to check the option sections
      return true;
    }
    for (let optionSection of this.itemForm.optionSections) {
      if (optionSection.name == '') {
        this.reponses.push('Option section name is not valid');
        return false;
      }
      for (let option of optionSection.options) {
        if (option.name == '' || option.amount == null) {
          if (option.name == '') {
            this.reponses.push('Option name is not valid');
          }
          if (option.amount == null) {
            this.reponses.push('Option amount is not valid');
          }
          return false;
        }
      }
    }
    return true;
  }

  addPrice() {
    let priceRespones = {
      name: '',
      amount: null,
      status: Status.Published
    } as PriceResponse
    this.itemForm.prices.push(priceRespones);
  }


  onCategorySelect() {
    console.log('Selected category:', this.selectedCategory);
  }
  addOption(category: OptionSectionResponse) {
    console.log('Adding new option to category:', category);
    category.options.push({ name: '', amount: 0, status: Status.Published });
  }

  // Add a new option category
  addOptionCategory() {
    console.log('Adding new option category');
    console.log(this.itemForm)
    if (this.itemForm.optionSections.length == 0) {
      this.itemForm.optionSections = [];
    }
    this.itemForm.optionSections.push({
      name: '',
      free: false,
      status: Status.Published,
      options: [
        { name: '', amount: null, status: Status.Published }
      ]
    } as OptionSectionResponse);
  }

  saveMenuItem() {
    console.log('Saving menu item:', this.itemForm);
    // Perform the save operation here, like sending the data to an API or service
    let isValid = this.checkData();
    if (!isValid) {
      console.log('Data is not valid');
      alert(this.reponses);
      return;
    }
    if (this.editingItem) {
      this.updateMenuItem();
      return
    }
    let menuItemRequest = {
      name: this.itemForm.name,
      description: this.itemForm.description,
      prices: this.itemForm.prices,
      status: Status.Published
    } as MenuItemRequest
    if (this.showOptionSections) {
      menuItemRequest.optionSections = this.itemForm.optionSections;
    }
    console.log('Menu item request:', menuItemRequest);
    this.menuItemService.createMenuItem(this.businessId, this.menuId, this.selectedCategory, menuItemRequest).subscribe((menuItem) => {
      console.log('Saved menu item:', menuItem);
      this.resetItemForm();
    });
  }

  updateMenuItem() {
    let menuItemRequest = {
      id: this.editingItem.id,
      name: this.itemForm.name,
      description: this.itemForm.description,
      prices: this.itemForm.prices,
      status: Status.Published
    } as MenuItemUpdateRequest
    if (this.showOptionSections) {
      menuItemRequest.optionSections = this.itemForm.optionSections;
    }
    this.menuItemService.updateMenuItem(this.businessId, this.menuId, this.editingItem.id, [menuItemRequest]).subscribe((menuItem) => {
      console.log('Updated menu item:', menuItem);
      this.resetItemForm();
    }, error => {
      console.log('Error updating menu item:', error);
    })
  }


  removePrice(index: number) {
    // Remove the price from the prices array based on its index
    this.itemForm.prices.splice(index, 1);
    console.log('Updated prices:', this.itemForm.prices);
  }

  removeOptionCategory(index: number) {
    // Remove the option category from the optionSections array based on its index
    this.itemForm.optionSections.splice(index, 1);
    console.log('Updated option categories:', this.itemForm.optionSections);
  }

  removeOption(index: number, category: any) {
    // Remove the option from the specific category's options array
    category.options.splice(index, 1);
    console.log('Updated options for category:', category.options);
  }

  private resetItemForm() {
    this.selectedCategory = null;
    this.itemForm = {
      name: '',
      description: '',
      prices: [{ name: '', amount: null }],
      optionSections: [{
        name: '',
        free: false,
        options: [{ name: '', amount: null }]
      }]
    } as MenuItemRequest;
  }

}
