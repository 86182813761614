/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderStatus } from './orderStatus';


export interface OrderUpdateRequest { 
    id?: number;
    /**
     * Price amount
     */
    price?: number;
    /**
     * Tip amount
     */
    tip?: number;
    /**
     * Additional Comment
     */
    reason?: string;
    status?: OrderStatus;
}

