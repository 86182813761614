/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DetailedOrderResponse } from './detailedOrderResponse';
import { DetailedOrderResponsePage } from './detailedOrderResponsePage';
import { OrderResponsePage } from './orderResponsePage';


/**
 * @type GetOrdersForBusiness200Response
 * @export
 */
export type GetOrdersForBusiness200Response = DetailedOrderResponsePage | OrderResponsePage;

