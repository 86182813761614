import { Injectable } from '@angular/core';
import { BusinessOwnerService, BusinessService, PrivilegeResponsePage } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeAllResolverService implements Resolve<PrivilegeResponsePage> {

  constructor(private businessApi: BusinessOwnerService, private apiConfigService: ApiConfigService) {
    this.businessApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<PrivilegeResponsePage> | Promise<PrivilegeResponsePage> | PrivilegeResponsePage {
    let businessId = Number(route.paramMap.get('businessId'))
    return this.businessApi.getPrivilegesForABusiness(businessId, 0)
  }
}
