import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePipe } from '../pipes/price.pipe';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({

  imports: [
    CommonModule,
    TranslateModule.forChild(),
  ],
  exports: [
    TranslateModule
  ]
})
export class SharedTranslateModule { }
