/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DetailedSelectedOption } from './detailedSelectedOption';
import { PriceResponse } from './priceResponse';


export interface DetailedOrderItemResponse { 
    id?: number;
    /**
     * Name of the ordered item
     */
    name?: string;
    /**
     * Additional Comment
     */
    comment?: string;
    price?: PriceResponse;
    /**
     * Quantity of the ordered item
     */
    quantity?: number;
    selectedOptions?: Array<DetailedSelectedOption>;
}

