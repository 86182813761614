/**
 * Servito AI Service
 * The Service for Servito AI that converts text or images to menu cards.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ImageResponse { 
    /**
     * Extracted text from the image
     */
    text: string;
}

