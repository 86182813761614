/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { AssistanceStatus } from './assistanceStatus';


export interface TableResponse { 
    id?: string;
    name?: string;
    /**
     * Table description
     */
    description?: string;
    number?: number;
    capacity?: number;
    businessUrl?: string;
    status?: Status;
    assistanceStatus?: AssistanceStatus;
}

