/**
 * Payment API
 * API for processing payments via Stripe
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RepresentativeAddress } from './representativeAddress';
import { Birthdate } from './birthdate';


export interface Representative { 
    /**
     * First name of the representative
     */
    firstName?: string;
    /**
     * Last name of the representative
     */
    lastName?: string;
    address?: RepresentativeAddress;
    birthdate?: Birthdate;
    /**
     * Phone number of the representative
     */
    phone?: string;
    /**
     * Gender of the representative
     */
    gender?: string;
    /**
     * Email of the representative
     */
    email?: string;
    /**
     * Job title of the representative
     */
    jobTitle?: string;
    /**
     * Whether the representative is verified
     */
    verified?: boolean;
}

