// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
ion-item {
  background-color: white !important;
  /* Setze den Hintergrund auf weiß */
}`, "",{"version":3,"sources":["webpack://./src/app/business-owner/menu/menu-editor/categories/categories.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,kCAAA;EACA,mCAAA;AAEJ","sourcesContent":["ion-item {\n    background-color: white !important;\n    /* Setze den Hintergrund auf weiß */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
