/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Role of a business owner
 */
export type Role = 'BUSINESS_OWNER' | 'BUSINESS_MANAGER' | 'BUSINESS_OPERATOR';

export const Role = {
    Owner: 'BUSINESS_OWNER' as Role,
    Manager: 'BUSINESS_MANAGER' as Role,
    Operator: 'BUSINESS_OPERATOR' as Role
};

