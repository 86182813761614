/**
 * Payment API
 * API for processing payments via Stripe
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Birthdate { 
    /**
     * Day of birth
     */
    day?: number;
    /**
     * Month of birth
     */
    month?: number;
    /**
     * Year of birth
     */
    year?: number;
}

