import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Common module for basic Angular functionality
import { IonicModule } from '@ionic/angular'; // To use Ionic components
import { CategoriesComponent } from './categories.component'; // Import CategoriesComponent
import { ItemsComponent } from '../items/items.component'; // Import ItemsComponent (assumed path)
import { ItemsModule } from '../items/items.modules';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        CategoriesComponent, // Declare CategoriesComponent
    ],
    imports: [
        CommonModule, // Basic Angular functionality
        IonicModule, // Import IonicModule for Ionic components
        FormsModule,
        ItemsModule
    ],
    exports: [
        CategoriesComponent // Export CategoriesComponent so it can be used in other modules
    ]
})
export class CategoriesModule { }
