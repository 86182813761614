/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderStatus } from './orderStatus';
import { OrderRequestItem } from './orderRequestItem';


export interface OrderRequest { 
    id?: number;
    items?: Array<OrderRequestItem>;
    /**
     * Tip amount
     */
    tip?: number;
    /**
     * Unique Id for a user not related to the servito system
     */
    frontendId?: string;
    /**
     * Additional Comment
     */
    comment?: string;
    status?: OrderStatus;
}

