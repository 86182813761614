/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SchemaOrgMenuSection } from './schemaOrgMenuSection';
import { SimpleMenuSectionList } from './simpleMenuSectionList';
import { SimpleMenuSectionListSectionsInner } from './simpleMenuSectionListSectionsInner';
import { SchemaOrgMenu } from './schemaOrgMenu';


/**
 * @type GetMenuById200Response
 * @export
 */
export type GetMenuById200Response = SchemaOrgMenu | SimpleMenuSectionList;

