/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { OptionResponse } from './optionResponse';


export interface OptionSectionRequest { 
    id?: number;
    /**
     * Option Section name
     */
    name?: string;
    /**
     * If the options are free
     */
    free?: boolean;
    options?: Array<OptionResponse>;
    /**
     * If the options are limited
     */
    limited?: boolean;
    min?: number;
    max?: number;
    sort?: number;
    status?: Status;
}

