import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private rolesCache$: Observable<string[]>;
  constructor(private auth: AuthService) { }
  decodeJwtPayload(token: string): any {
    if (token == null) {
      return null;
    }
    // Splits the JWT into its components.
    const parts = token.split('.');
    if (parts.length < 3) {
      throw new Error("JWT does not contain 3 parts.");
    }

    const payload = parts[1];
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/'); // Adjusts URL-safe Base64 characters
    let decoded;
    try {
      decoded = atob(base64);
    } catch (e) {
      console.error('Failed to decode base64 string', e);
      return null;
    }

    try {
      return JSON.parse(decoded); // Parses the JSON string payload into an object
    } catch (e) {
      console.error('Failed to parse JWT payload', e);
      return null;
    }
  }

  getRoles(): Observable<string[]> {
    if (!this.rolesCache$) {
      this.rolesCache$ = this.auth.idTokenClaims$.pipe(
        map(claims => {
          // Adjusting this line to fetch roles directly without namespace
          return claims?.realm_access?.roles as string[] || [];
        }),
        catchError(() => of([])),  // Return an empty array in case of an error
        shareReplay(1)  // Cache the latest value
      );
    }
    return this.rolesCache$;
  }

  hasRequiredRole(requiredRoles: string[]): Observable<boolean> {
    return this.getRoles().pipe(
      map(userRoles => requiredRoles.some(requiredRole => userRoles.includes(requiredRole)))
    );
  }
}
