import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';  // Füge das IonicModule hinzu

import { BillEntryComponent } from './bill-entry.component'; // Dein Komponent
import { PricePipe } from 'src/app/pipes/price.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedPriceModule } from 'src/app/shared/shared-price.module';
import { SharedTranslateModule } from 'src/app/shared/shared-translate.module';

@NgModule({
    declarations: [BillEntryComponent],
    imports: [
        CommonModule,
        IonicModule,
        SharedPriceModule,
        SharedTranslateModule
    ],
    exports: [BillEntryComponent],
})
export class BillEntryModule { }
