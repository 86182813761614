/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MenuResponse } from './menuResponse';


export interface MenuResponsePage { 
    totalElements?: number;
    totalPages?: number;
    page?: number;
    size?: number;
    content?: Array<MenuResponse>;
}

