import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { EditItemComponent } from './edit-item.component';

@NgModule({
    declarations: [EditItemComponent],
    imports: [CommonModule, IonicModule, FormsModule],
    exports: [EditItemComponent] // Export to make it available to other modules
})
export class EditItemModule { }
