/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SchemaOrgMenuSection } from './schemaOrgMenuSection';


export interface SchemaOrgMenu { 
    context?: string;
    type?: string;
    id?: number;
    name?: string;
    description?: string;
    hasMenuSection?: Array<SchemaOrgMenuSection>;
}

