/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { PriceResponse } from './priceResponse';
import { OptionSectionResponse } from './optionSectionResponse';


export interface MenuItemRequest { 
    /**
     * Business name
     */
    name?: string;
    /**
     * Image Id
     */
    image?: string;
    /**
     * Business Description
     */
    description?: string;
    prices?: Array<PriceResponse>;
    optionSections?: Array<OptionSectionResponse>;
    status?: Status;
}

