/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { UserResponse } from './userResponse';


export interface PrivilegeResponse { 
    id?: number;
    user?: UserResponse;
    role?: Role;
    /**
     * The id of the business
     */
    businessId?: number;
}

