import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorAlertService {

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService
  ) { }

  async presentErrorAlert(header: string, subHeader: string, message: string, button: string) {
    message = message.replace(/\,/g, '')
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [button],
    });

    await alert.present();
  }

  async presentUnexpectedErrorAlert(error) {
    const alert = await this.alertController.create({
      header: this.translateService.instant("ERRORS.ERROR"),
      subHeader: this.translateService.instant("ERRORS.UNEXPECTED_ERROR"),
      message: this.translateService.instant("ERRORS.PROBLEM_SUPPORT_INFO", { supportInfo: error }),
      buttons: [this.translateService.instant("ERRORS.OK")],
    });

    await alert.present();
  }
}
