/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Day of the week
 */
export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const DayOfWeek = {
    Monday: 'MONDAY' as DayOfWeek,
    Tuesday: 'TUESDAY' as DayOfWeek,
    Wednesday: 'WEDNESDAY' as DayOfWeek,
    Thursday: 'THURSDAY' as DayOfWeek,
    Friday: 'FRIDAY' as DayOfWeek,
    Saturday: 'SATURDAY' as DayOfWeek,
    Sunday: 'SUNDAY' as DayOfWeek
};

