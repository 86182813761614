import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BusinessService } from './api/business.service';
import { BusinessConfigService } from './api/businessConfig.service';
import { BusinessOwnerService } from './api/businessOwner.service';
import { BusinessPageService } from './api/businessPage.service';
import { HolidaysService } from './api/holidays.service';
import { MenuService } from './api/menu.service';
import { MenuItemService } from './api/menuItem.service';
import { MenuSectionService } from './api/menuSection.service';
import { OpeningHoursService } from './api/openingHours.service';
import { OrderService } from './api/order.service';
import { TableService } from './api/table.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
