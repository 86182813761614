import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuItemResponse } from 'src/app/class/responses/menu';
import { MenuItemService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class MenuItemByIdResolverService implements Resolve<MenuItemResponse> {

  constructor(private apiConfigService: ApiConfigService, private menuItemApi: MenuItemService) {
    this.menuItemApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let businessId = Number(route.paramMap.get('businessId'))
    let menuId = Number(route.paramMap.get('menuId'))
    let menuSectionId = Number(route.paramMap.get('menuSectionId'))
    let menuItemId = Number(route.paramMap.get('menuItemId'))
    return this.menuItemApi.getMenuItemById(businessId, menuId, menuSectionId, menuItemId)
  }
}
