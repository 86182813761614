/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { BusinessImageResponse } from './businessImageResponse';


export interface BusinessUpdateRequest { 
    id?: number;
    /**
     * Business name
     */
    name?: string;
    /**
     * The reserved url of a Business
     */
    url?: string;
    logo?: BusinessImageResponse;
    header?: BusinessImageResponse;
    /**
     * Business Description
     */
    description?: string;
    status?: Status;
}

