import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OpeningHoursResponse, OpeningHoursService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class EditOpeningHoursResolverService implements Resolve<OpeningHoursResponse> {

  constructor(private apiConfigService: ApiConfigService, private openingHoursService: OpeningHoursService) {
    this.openingHoursService.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let businessId = Number(route.paramMap.get('businessId'))
    let openingHourId = Number(route.paramMap.get('openingHourId'))
    return this.openingHoursService.getOpeningHour(businessId, openingHourId)
  }
}
