export class UserData {
    id: string
    avatar: string
    language: string
    name: string
    first_name: string
    last_name: string
    email: string
    location: string
    role: string
}
export class UserDataRequest {
    data: UserData[];
}
export class OneUserDataRequest {
    data: UserData;
}