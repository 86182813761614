import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuSectionResponse, MenuSectionService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class MenuSectionResolverService implements Resolve<MenuSectionResponse> {

  constructor(private apiConfigService: ApiConfigService, private menuSectionApi: MenuSectionService) {
    this.menuSectionApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let businessId = Number(route.paramMap.get('businessId'))
    let menuId = Number(route.paramMap.get('menuId'))
    let menuSectionId = Number(route.paramMap.get('menuSectionId'))
    return this.menuSectionApi.getMenuSectionById(businessId, menuId, menuSectionId)
  }
}
