/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { BusinessImageResponse } from './businessImageResponse';


export interface BusinessResponse { 
    id?: number;
    /**
     * Business name
     */
    name?: string;
    /**
     * Business currency
     */
    currency?: string;
    /**
     * The reserved url of a Business
     */
    url?: string;
    /**
     * Business Description
     */
    description?: string;
    /**
     * Business QR Code Identifier
     */
    qrCodeIdentifier?: string;
    logo?: BusinessImageResponse;
    header?: BusinessImageResponse;
    status?: Status;
    owners?: Array<number>;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: number;
    updatedBy?: number;
}

