import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessOwnerService, BusinessResponsePage } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessOwnerResolverService implements Resolve<BusinessResponsePage> {

  constructor(private apiConfigService: ApiConfigService, private businessOwnerApi: BusinessOwnerService) {
    this.businessOwnerApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.businessOwnerApi.getBusinessesForOwner(0)
  }
}
