import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectService {
  minutes = 30;
  constructor(
    private platform: Platform,
    private alertController: AlertController
  ) { }

  isUsableBrowser() {
    this.platform.ready().then(() => {
      this.checkBrowser();
    });
  }

  async checkBrowser() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isChromium = userAgent.includes('chrome') || userAgent.includes('chromium') || userAgent.includes('edg');
    if (!isChromium) {
      const lastShown = localStorage.getItem('BROWSER_ALERT_LAST_SHOWN');
      const now = new Date().getTime();

      let time = 60000 * this.minutes; // 60000 ms = 1 minute
      if (!lastShown || (now - parseInt(lastShown, 10)) > time) {
        await this.showBrowserAlert();
        localStorage.setItem('BROWSER_ALERT_LAST_SHOWN', now.toString());
      }
    }
  }

  async showBrowserAlert() {
    const alert = await this.alertController.create({
      header: 'Browser nicht unterstützt',
      message: 'Für die beste Benutzererfahrung verwenden Sie bitte Edge oder Chrome.',
      buttons: [{
        text: `Für ${this.minutes} Minute ausblenden`,
        handler: () => {
          // Optional: handle the click event if needed
        }
      }]
    });

    await alert.present();
  }
}
