/**
 * Servito Service
 * Servito Service
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@servito.me
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Common status for various entities
 */
export type Status = 'PUBLISHED' | 'ARCHIVED' | 'DRAFT' | 'SOLD_OUT';

export const Status = {
    Published: 'PUBLISHED' as Status,
    Archived: 'ARCHIVED' as Status,
    Draft: 'DRAFT' as Status,
    SoldOut: 'SOLD_OUT' as Status
};

