import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TableResponse, TableService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class TableByBusinessResolverService implements Resolve<TableResponse> {

  constructor(private apiConfigService: ApiConfigService, private tableApi: TableService) {
    this.tableApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let id = Number(route.paramMap.get('businessId'))
    return this.tableApi.getTables(id, 0)
  }
}
