import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorAlertService } from '../services/error/error-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private errorAlertService: ErrorAlertService,
        private translateService: TranslateService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth.isAuthenticated$.pipe(
            concatMap(isAuthenticated => {
                if (isAuthenticated) {
                    return this.auth.getAccessTokenSilently().pipe(
                        concatMap(token => {
                            request = request.clone({
                                setHeaders: { Authorization: `Bearer ${token}` }
                            });
                            return next.handle(request);
                        }),
                        catchError(error => this.handleError(error))
                    );
                }
                return next.handle(request).pipe(
                    catchError(error => this.handleError(error))
                );
            }),
            catchError(error => this.handleError(error))
        );
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = this.translateService.instant('ERRORS.CANT_REACH_SERVER');
        if (error.status === 500) {
            errorMessage = this.translateService.instant('ERRORS.PROBLEM_SUPPORT_INFO', { supportInfo: error.message });
            this.errorAlertService.presentErrorAlert('Error', errorMessage, this.translateService.instant('ERRORS.PROBLEM_TRY_LATER'), this.translateService.instant('ERRORS.OK'));
        }
        return throwError(error);
    }
}